import Header from '../components/header';
import Footer from '../components/footer';

function Contacts() {
  return (
    <div className="mainContainer">
      <Header />
      <div className='contactsContainer'>
        <div className='contactsContainer__map'>
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1047.0085664786302!2d26.7176342!3d58.3425123!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46eb374ea316ea17%3A0x8d881c877c59112d!2sRolfo%20Ltd.!5e0!3m2!1sen!2see!4v1610355250333!5m2!1sen!2see" class='contactsContainer__map'></iframe>
        </div>
        <div className='contactsContainer__info'>
          <h1>Rolfo OÜ</h1>
          <text>Ringtee 1, Tartu 51013</text>
          <text>Reg.nr:11976508</text>
          <text>+372 53 814 290</text>
          <text>oyrolfo@gmail.com</text>
          <text>A/a: EE622200221050044824</text>
          <h1>Oleme laos</h1>
          <text>Teisipäev, neljapäev ja laupäev</text>
          <text>11.00 - 15.00</text>
          <text>Kui soovite näha teistel aegadel siis helistage numbril 53814290</text>
          <text>Saadame mööblit üle Eesti kulleriga</text>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default Contacts
